import React, { useEffect } from "react";
import Step from "../components/stepper/step";
import StepButton from "../components/stepper/stepButton";
import Stepper from "../components/stepper/stepper";

export default function () {
  return <div style={{
    width: '100vw',
    height: '100vh',
    background: 'white',
    padding: 100,
    fontSize: 50,
  }}>
    <Stepper activeStep={1}>
      <Step>
        ONE <StepButton action="next">next</StepButton>
      </Step>
      <Step>
        TWO <StepButton action="next">prev</StepButton>
      </Step>
      <Step>
        THREE <StepButton action='goTo' index={1}>goTo first</StepButton>
      </Step>
    </Stepper>
  </div>
}
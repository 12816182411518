import React, { FC, useContext, MouseEvent } from "react";
import StepperContext from "../../context/StepperContext";
import { StepButtonProps, StepperAction } from "./types";

const StepButton: FC<StepButtonProps> = ({
  action,
  children,
  index,
  onClick,
}) => {
  const { dispatch } = useContext(StepperContext);

  const handleOnClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    onClick && onClick(action);

    const data: StepperAction = {
      type: action,
    };

    switch (action) {
      case "goTo":
        data.payload = { index };
        break;
      default:
        break;
    }

    console.log("dispatch data,", data);
    dispatch && dispatch(data);
  };

  return (
    <button
      style={{
        border: "none",
        margin: 0,
        padding: 0,
        outline: "none",
        cursor: "pointer",
        width: "fit-content",
      }}
      onClick={handleOnClick}
      children={children}
    />
  );
};

export default StepButton;

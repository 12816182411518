import React, { FC, forwardRef, useReducer, ReactElement } from 'react'
import { StepperProvider, stepperReducer } from '../../context/StepperContext'
import { StepperProps } from './types'

interface StepperRootProps {
  ref: React.Ref<unknown>
}

const StepperRoot: FC<StepperRootProps> = ({ children }) => {
  return <>{children}</>
}

const Stepper = forwardRef<ReactElement, StepperProps>(({
  activeStep = 0,
  children,
  ...props
}, ref) => {

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const [state, dispatch] = useReducer(stepperReducer, { activeStep })

  const childrenArray = React.Children.toArray(children).filter(Boolean)
  const steps = childrenArray.map((step: any, index: number) => React.cloneElement(step, { // props added to the clonded element
    index,
    last: index + 1 === childrenArray.length,
    ...step.props,
  }))

  return (<StepperProvider value={{ ...state, dispatch }}>
    <StepperRoot
      ref={ref}
      {...props}>
      {steps}
    </StepperRoot>
  </StepperProvider>)
})

Stepper.displayName = 'Stepper'
export default Stepper
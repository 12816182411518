import { createContext } from "react";


const StepContext = createContext({})
StepContext.displayName = 'StepContext'

export default StepContext
export const {
  Provider: StepProvider,
  Consumer: StepConsumer
} = StepContext
/**
 * Resources
 * https://github.com/tranc99/typescript-use-reducer/blob/master/src/App.tsx
 * https://gist.github.com/sw-yx/f18fe6dd4c43fddb3a4971e80114a052
 */
import { createContext } from "react";
import { StepperAction, StepperState } from "../components/stepper/types";

const initialStepperState = {
  activeStep: 0,
}
const StepperContext = createContext<StepperState|null>(null)

export const stepperReducer = (
  { activeStep, ...state }: StepperState,
  action: StepperAction
): StepperState => {
  switch (action.type) {
    case 'prev': {
      return { ...state, activeStep: activeStep - 1 }
    }
    case 'next': {
      return { ...state, activeStep: activeStep + 1 }
    }
    case 'goTo': {
      return { ...state, activeStep: action.payload?.index ?? activeStep }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

StepperContext.displayName = 'StepperContext'
export default StepperContext
export const {
  Provider: StepperProvider,
  Consumer: StepperConsumer
} = StepperContext
import React, { FC, forwardRef, useContext, useEffect, useMemo, useState } from 'react'
import { StepProvider } from '../../context/StepContext'
import StepperContext from '../../context/StepperContext'
import { StepProps } from './types'

export const Step = forwardRef<unknown, StepProps>( ({
  index,
  last,
  active,
  completed,
  disabled,
  children,
}, ref) => {
  
  const context = useContext(StepperContext)
  let isActive = context?.activeStep === index 

  if( context?.activeStep === index ) {
    isActive = true
  }
  
  const contextValue = useMemo(() => ({
    index,last, active: isActive, completed, disabled
  }),[index,last, isActive, completed, disabled])

  return isActive?<StepProvider value={contextValue}>
    {children}
  </StepProvider>:null
})

export default Step